.destino-info {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  .col-6:hover .destino-info {
    opacity: 1;
    transform: translateY(0);
  }

  .quadro {
    background-color: #ffffff;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .quadro-fixo {
    position: absolute;
    top: 10%;
    left: 0;
    background-color: #dc3545;
    padding: 1rem;
    border-radius: 5px;
    z-index: 2;
  }
  .quadro-fixo h3{
    color : white;
  }

  .quadro-fixo-banner {
    position: absolute;
    font-family: cursive;
    top: 10%;
    width: 50%;
    background-color: white;
    padding: 1rem;
    border-radius: 5px;
    opacity: 70%;
    margin: auto;
  }

  .quadro-animado {
    position: absolute;    
    font-family:cursive;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: rgba(255, 255, 255, 0.7);
    color: #000;
    padding: 1rem;
    border-radius: 5px;
    transition: bottom 0.3s ease;
    display:none; /* Inicialmente, esconderemos o quadro animado */
  }

  .quadro-mobile {
    margin: 20px;
    height: 70vh;
    background-color: #ffffff;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .quadro-mobile img{
    width: 100%;
    height: 100%;
  }
  
  .quadro-animado-mobile {
    position: absolute;    
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.7);
    color: black;
    padding: 1rem;
    border-radius: 5px;
    display:block; /* Inicialmente, esconderemos o quadro animado */
  }

  .quadro-animado-mobile p{
    font-size: 11px;
    line-height: initial;
  }

  .imagem{
    width: 100%;
    height: 100%;
  }
  .quadro-animado p {
    font-size: smaller;
    line-height: initial;
  }

  .quadro-1:hover {
    z-index: 9999;
  }

  .quadro-1:hover .quadro-animado {
    color: black;
    display:flex;
    flex-direction: column;
    align-itens:center;
    content-align:center;
    top: 57%; /* Quando o mouse passar sobre o quadro animado, ele irá subir */
    width: 100%;
    height: 40%;
  }

  .quadro-2 {
    left: -50px;
    top: 50px;
    height: 400px;
  }
  .quadro-3 {
    top: -380px;
    left: 60%;
    height: 50vh;
    width: 50%;
  }
  .quadro-4 {
    width : 50%;
    top: -220px;
    left: -30%;
    height: 500px;
  }
  
  .quadro-2:hover .quadro-animado {
    top: 40%; /* Quando o mouse passar sobre o quadro animado, ele irá subir */
    height: 60%;
  }
